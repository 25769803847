<template>
    <b-row class="mt-2">
      <b-col lg="2">

      </b-col>
    </b-row>
</template>

<script>

export default {
  name: 'Index-page',
  data: () => ({
  }),
  components: {
  },
  methods: {
  },
 async mounted() {
   const dildoToken = this.$cookies.get("dildo-token");
   if (!dildoToken) this.$router.push({path: "/login"});
   if (dildoToken) {
     // await this.getData()
   }
  }
}
</script>

